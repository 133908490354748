import { Logger } from '@grandstand-web/bally-web-shared/src/utils/logger';
export const getEntitlementsInRegion = (userEntitlements, networks) => {
    return userEntitlements.filter((entitlement) => {
        const areas = entitlement.entitlement_ids || [];
        return areas.some((area) => networks.includes(area));
    });
};
export const getEntitlementsByPermittedType = (userEntitlements, contentType) => {
    return userEntitlements.filter((entitlement) => {
        return contentType === 'mvpd' ? entitlement.type === 'mvpd' : true;
    });
};
const separatePausedEntitlements = (userEntitlements) => {
    const isPausedEntitlement = (entitlement) => {
        return entitlement.subscription_status === 'paused' || entitlement.subscription_status === 'seasonal_paused';
    };
    return userEntitlements.reduce((result, entitlement) => {
        if (isPausedEntitlement(entitlement)) {
            result[1].push(entitlement);
        }
        else {
            result[0].push(entitlement);
        }
        return result;
    }, [[], []]);
};
export const getEntitlementInfo = (userEntitlements, contentMeta) => {
    const logger = Logger.of('Entitlements > getEntitlementInfo');
    const logStatus = (status) => {
        logger.debug(`User${status.entitled ? '' : 'NOT'} entitled reason: ${status.reason}. Derived from this data:`, {
            userEntitlements,
            contentMeta,
        });
    };
    const [entitlements, pausedEntitlements] = separatePausedEntitlements(userEntitlements);
    const _getInfo = () => {
        if (contentMeta === undefined) {
            return { entitled: true, reason: 'no-restrictions' };
        }
        if (!(contentMeta === null || contentMeta === void 0 ? void 0 : contentMeta.entitlement_ids)) {
            return { entitled: true, reason: 'no-network-restrictions' };
        }
        if (!contentMeta.entitlement_type || contentMeta.entitlement_type === 'free') {
            return { entitled: true, reason: 'free-content' };
        }
        let status = { entitled: false, reason: 'no-entitlements' };
        const regionEntitlements = getEntitlementsInRegion(entitlements, contentMeta.entitlement_ids);
        if (regionEntitlements.length === 0) {
            status = { entitled: false, reason: 'out-of-region' };
        }
        else {
            const validContentEntitlements = getEntitlementsByPermittedType(regionEntitlements, contentMeta.entitlement_type);
            if (validContentEntitlements.length === 0) {
                status = { entitled: false, reason: 'mvpd-only' };
            }
            if (validContentEntitlements.length > 0) {
                return { entitled: true, reason: `in-region-${contentMeta.entitlement_type}` };
            }
        }
        const validPausedEntitlements = getEntitlementsByPermittedType(getEntitlementsInRegion(pausedEntitlements, contentMeta.entitlement_ids), contentMeta.entitlement_type);
        if (validPausedEntitlements.length > 0) {
            return { entitled: false, reason: validPausedEntitlements[0].subscription_status || '' };
        }
        return status;
    };
    const status = _getInfo();
    logStatus(status);
    return status;
};
