import { ModalContext } from '@/contexts/ModalContext'
import { usePurchasingDisabled } from '@grandstand-web/bally-web-shared/src/hooks/usePurchasingDisabled'
import { UpsellMode, UpsellState } from '@grandstand-web/bally-web-shared/src/hooks/useUpsellLogic'
import { getString } from '@grandstand-web/bally-web-shared/src/newPackages/StringMgmt'
import { AnalyticServiceContext } from '@grandstand-web/bally-web-shared/src/services/analytics/AnalyticService'
import { useContextUnconditionally } from '@grandstand-web/bally-web-shared/src/services/config/ConfigService'
import { TealiumServiceContext } from '@grandstand-web/bally-web-shared/src/services/tealium/TealiumService'
import { UserServiceContext } from '@grandstand-web/bally-web-shared/src/services/user/UserService'
import { useRouter } from 'next/router'
import { useContext } from 'react'
import * as Styles from './styles'

export const UpsellActions = ({ upsell, outOfMarket }: { upsell: UpsellState; outOfMarket: boolean }) => {
  const { contentUrl } = upsell
  const { isLoggedIn, freeTrialStatus } = useContextUnconditionally(UserServiceContext)
  const { closeModal } = useContext(ModalContext)
  const router = useRouter()
  const isSubscribe = upsell.mode === UpsellMode.subscribe
  const mvpdButtonStyle = isSubscribe ? 'alt' : 'primary'
  const analyticService = useContext(AnalyticServiceContext)
  const tealium = useContext(TealiumServiceContext)
  const { isPurchasingDisabled } = usePurchasingDisabled()

  const showSubscribeButton = () => !isPurchasingDisabled && isSubscribe
  const showBuySingleGameButton = () => !isPurchasingDisabled && upsell.isRedeemable

  const handleSignInDTC = () => {
    // tealium analytics
    tealium.upsellSignIn()
    // close modal + redirect to packages page
    closeModal()
    const loggedInPath = `/packages?redirect_url=${encodeURIComponent(contentUrl)}`
    const url = isLoggedIn ? loggedInPath : `/signin?redirect_url=${encodeURIComponent(loggedInPath)}`
    router.replace(url)
  }
  const handleSignInMVPD = () => {
    // tealium analytics
    tealium.upsellSignInWithTVProvider()
    // close modal + redirect to mvpd signin
    closeModal()
    router.replace(`/mvpd/signin?redirect_url=${encodeURIComponent(contentUrl)}`)
  }

  const handleSignIn = () => {
    if (upsell.mode === 'mvpd') {
      handleSignInMVPD()
    } else {
      handleSignInDTC()
    }
  }
  const handleGetBallySportsPlusClick = () => {
    // google analytics
    analyticService.clickGetBallySportsPlus(
      getString('onboarding.authentication.getBallyPlus.cta') ?? 'Get FanDuel Sports Network'
    )

    // tealium analytics - start trial if free trial is available
    if (freeTrialStatus) {
      tealium.upsellStartTrial()
    }
    // close modal + redirect to packages page
    closeModal()
    router.replace('/packages')
  }

  const handleBuySingleGameClick = () => {
    // google analytics
    analyticService.clickGetBallySportsPlus(getString('onboarding.authentication.buySingleGame.cta'))

    // close modal + redirect to packages page
    closeModal()
    router.replace(`/purchase?redirect_url=${encodeURIComponent(contentUrl)}`)
  }

  return (
    <Styles.Actions>
      {showSubscribeButton() ? (
        <>
          <Styles.Action>
            <Styles.Button
              buttonSize="sm"
              buttonStyle="primary"
              onClick={() => handleGetBallySportsPlusClick()}
              disabled={outOfMarket || undefined}
            >
              {freeTrialStatus ? getString('onboarding.authentication.getBallyPlus.cta') : 'Subscribe'}
            </Styles.Button>
          </Styles.Action>
        </>
      ) : null}
      {showBuySingleGameButton() ? (
        <>
          <Styles.Action>
            <Styles.Button
              buttonSize="sm"
              buttonStyle="primary"
              onClick={() => handleBuySingleGameClick()}
              disabled={outOfMarket || undefined}
            >
              {getString('onboarding.authentication.buySingleGame.cta')}
            </Styles.Button>
          </Styles.Action>
        </>
      ) : null}
      {showSubscribeButton() || showBuySingleGameButton() ? (
        <Styles.ActionOr>
          <Styles.OrText fontSize="1">OR</Styles.OrText>
        </Styles.ActionOr>
      ) : null}
      <Styles.Action>
        <Styles.Button
          buttonSize="sm"
          buttonStyle={mvpdButtonStyle}
          onClick={(event) => {
            event.preventDefault()
            handleSignInMVPD()
          }}
          disabled={outOfMarket || undefined}
        >
          Connect TV Provider
        </Styles.Button>
      </Styles.Action>
      {!isLoggedIn && !isPurchasingDisabled ? (
        <Styles.Action style={{ textAlign: 'center' }}>
          <Styles.SignInMeta fontSize="sm">
            Already have a FanDuel Sports Network account?{' '}
            <Styles.InlineLink
              href={`/signin?redirect_url=/${upsell.contentUrl}`}
              onClick={(event) => {
                event.preventDefault()
                handleSignIn()
              }}
            >
              Sign In
            </Styles.InlineLink>
          </Styles.SignInMeta>
        </Styles.Action>
      ) : null}
    </Styles.Actions>
  )
}
