import { UserServiceContext } from '@grandstand-web/bally-web-shared/src/services/user/UserService'
import { EntitlementMeta, TEntitlementReason, getEntitlementInfo } from '@grandstand-web/entitlements'
import { AuthProfileOutput, MetaEntitlementType } from '@grandstand/presentation-models'
import { useContext, useEffect, useState } from 'react'

export enum UpsellMode {
  entitled = 'entitled',
  subscribe = 'subscribe',
  mvpd = 'mvpd',
  credit = 'credit',
}

export type UpsellState = {
  isEntitled: boolean | undefined
  mode: UpsellMode
  reason: TEntitlementReason
  contentUrl: string
  isRedeemable: boolean
  hasEnoughCredits: boolean | undefined
  meta: EntitlementMeta | undefined
}

export type UpsellStore = {
  upsell: UpsellState
}
export type UpsellProps = {
  contentUrl: string
  meta: EntitlementMeta | undefined
}

export const useUpsellLogic = ({ contentUrl = '/', meta }: UpsellProps) => {
  const { isDTC, isMVPD, isLoggedIn, currentUser } = useContext(UserServiceContext)
  const [upsell, setUpsell] = useState<UpsellState>({
    contentUrl,
    meta,
    isEntitled: true,
    reason: '',
    isRedeemable: false,
    hasEnoughCredits: false,
    mode: UpsellMode.entitled,
  })
  useEffect(() => {
    const next = getUpsellState({ contentUrl, meta, currentUser })
    setUpsell(next)
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [currentUser, meta?.local_network_ids, meta?.entitlement_type, isLoggedIn, isDTC, isMVPD, meta])

  return {
    upsell,
  }
}
type GetUpsellStateProps = {
  contentUrl: string
  meta: EntitlementMeta | undefined
  // userService: UserService
  currentUser?: AuthProfileOutput
}

export const getUpsellState = ({ contentUrl, meta, currentUser }: GetUpsellStateProps): UpsellState => {
  // convert the useEffect above into a functon that returns UpsellState
  const redeemableAmount = meta?.redeemable_amount || 0
  const contentType: MetaEntitlementType | undefined = meta?.entitlement_type
  const isMVPD = contentType === 'mvpd'
  const isRedeemable = redeemableAmount > 0
  const credits = currentUser?.profile?.wallet?.credits || 0
  const hasEnoughCredits = isRedeemable && credits >= redeemableAmount
  const mode = isMVPD ? UpsellMode.mvpd : UpsellMode.subscribe
  const getState = (): UpsellState => {
    const createState = (isEntitled: boolean, reason: TEntitlementReason): UpsellState => {
      const next: UpsellState = {
        meta,
        contentUrl,
        isEntitled,
        isRedeemable,
        hasEnoughCredits,
        mode,
        reason,
      }
      return next
    }

    if (currentUser === undefined) {
      return createState(false, '')
    }

    const entitlements = [...currentUser.profile.entitlements, ...currentUser.profile.paused_entitlements]
    const entitlementInfo = getEntitlementInfo(entitlements, meta)

    const state = createState(entitlementInfo.entitled, entitlementInfo.reason)
    return state
  }

  const upsellState = getState()
  return upsellState
}
