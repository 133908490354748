import { ModalContext } from '@/contexts/ModalContext'
import { UpsellState } from '@/hooks/useUpsell'
import { GrandstandIcon } from '@grandstand-web/bally-web-shared/src/components/GrandstandIcon'
import { TealiumServiceContext } from '@grandstand-web/bally-web-shared/src/services/tealium/TealiumService'
import { MouseEventHandler, useContext } from 'react'
import * as Styles from './styles'
import UpsellModalDefaultContent from './upsellModalDefaultContent'
import UpsellModalSeasonPauseContent from './upsellModalSeasonPauseContent'

export interface UpsellModalProps {
  upsell: UpsellState
}

export const UpsellModal = (props: UpsellModalProps) => {
  const { closeModal, isOpen } = useContext(ModalContext)
  const tealium = useContext(TealiumServiceContext)

  const handleClose = () => {
    tealium.upsellClose()
    closeModal()
  }
  const handleClickOutside: MouseEventHandler<HTMLDivElement> = (event) => {
    if (event.target === event.currentTarget) {
      handleClose()
    }
  }

  const onRequestClose = () => {
    handleClose()
  }
  return (
    <Styles.Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Example Styles.Modal"
      style={{
        content: {
          position: 'static',
          inset: 'unset',
          justifySelf: 'flex-end',
          width: '100%',
          height: 'auto',
          border: 'none',
          overflowY: 'scroll',
          backgroundColor: 'transparent',
          borderRadius: '0',
          padding: '0',
        },
        overlay: {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-end',
          width: '100vw',
          height: '100vh',
          zIndex: '1000000',
          overflow: 'unset',
          backgroundColor: 'rgba(0, 0, 0, 0.4)',
        },
      }}
    >
      <Styles.ModalStyles data-theme="light">
        <Styles.Wrapper onClick={handleClickOutside}>
          <Styles.Container padded={true}>
            <Styles.Grid>
              {props.upsell.reason === 'seasonal_paused' ? (
                <UpsellModalSeasonPauseContent {...props} />
              ) : (
                <UpsellModalDefaultContent {...props} />
              )}
            </Styles.Grid>
            <Styles.CloseRow>
              <Styles.CloseButton onClick={() => handleClose()} title="Close">
                <GrandstandIcon icon="close" />
              </Styles.CloseButton>
            </Styles.CloseRow>
          </Styles.Container>
        </Styles.Wrapper>
      </Styles.ModalStyles>
    </Styles.Modal>
  )
}
